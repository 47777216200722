<template>
  <div class="formCheck">
    <div class="exp-item-title-container" @click="$emit('toggle-tab', 2)">
      <div class="left">
        <span>2. Uw persoonlijke gegevens</span>
      </div>
      <div class="right">
        <div class="arrow"></div>
      </div>
    </div>
    <div class="exp-item-text">
      <div class="sep-30"></div>
      <div class="exp-item-body">
        <div class="row-11-a radio-input">
          <div class="col">
            <div class="txt-label">{{ formData.title.ui.label }}</div>
          </div>
          <div class="col">
            <div class="inputs">
              <input-radio v-model="formData.title">
                <span slot="option1">Dhr</span>
                <span slot="option2">Mevr</span>
              </input-radio>
            </div>
          </div>
        </div>
        <div class="row-11-a">
          <div class="col">
            <div class="txt-label">{{ formData.initials.ui.label }}</div>
          </div>
          <div class="col">
            <div class="inputs">
              <input-text v-model="formData.initials"></input-text>
            </div>
          </div>
        </div>

        <div class="row-11-a">
          <div class="col">
            <div class="txt-label">{{ formData.prefix.ui.label }}</div>
          </div>
          <div class="col">
            <div class="inputs">
              <input-text v-model="formData.prefix"></input-text>
            </div>
          </div>
        </div>

        <div class="row-11-a">
          <div class="col">
              <div class="txt-label">{{ formData.lastName.ui.label }}</div>
          </div>
          <div class="col">
            <div class="inputs">
              <input-text v-model="formData.lastName"></input-text>
            </div>
          </div>
        </div>

        <div class="row-11-a date-input">
          <div class="col">
            <div class="txt-label">Geboortedatum</div>
          </div>
          <div class="col">
            <div class="inputs">
              <input-date v-model="formData.birthDate"></input-date>
            </div>
          </div>
        </div>
        
        <div class="sep-10"></div>
        
        <btn-big
          class="secondary-pink"
          :width="240"
          :height="80"
          v-show="!nextTab.expand"
          @click="$emit('toggle-tab', 3)"
        >
          <div class="content">
            <div class="txt">Volgende</div>
            <img src="@/assets/arrow.svg">
          </div>
        </btn-big>
        
        <div class="sep-30"></div>

      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;

.area {
  background-color: getColor("greyLight");
  .exp-item-title-container {
    height: pxToEm(100);
    padding: 0 pxToEm(50);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &.valid {
      color: getColor("greenDefault");
    }

    .left {
      height: 100%;
      flex: 2 1 auto;
      display: flex;
      align-items: center;
      font-size: pxToPercent(24);
    }

    .right {
      width: pxToEm(100);
      height: pxToEm(100);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .arrow {
        position: relative;
        border: solid black;
        border-width: 0 pxToEm(2) pxToEm(2) 0;
        padding: 5px;
        transform: rotate(45deg);
      }
    }
  }

  .exp-item{
    &.expanded {
      height: auto;

      .exp-item-title-container {
        .right {
          .arrow {
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  .error {
    color: getColor("redColor");
    font-style: italic;
  }

  .exp-item-text {
    padding: 0 pxToEm(100);

    .exp-item-body {
      display: grid;
      grid-row-gap: @gridRowGap;

      .row-11-a {
        height: pxToEm(70);
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-column-gap: @gridColGap;
        align-items: center;

        .txt-label{
          font-size: 112.5%;
          font-weight: 300;
          line-height: 2.2em;
        }
      }
    }
  }

  .radio-input {
      position: relative;
  }
  
  .btn-big {
    margin-left: auto;

    .content {
      margin-left: pxToEm(30);
      margin-right: pxToEm(30);

      .txt {
        font-size: pxToPercent(20);
        font-weight: 700;
      }

      img {
        width: pxToEm(48);
        height: pxToEm(38);
      }
    }
  }
}
</style>

<script>
import inputText from "@/components/common/input-text";
import inputDropdown from "@/components/common/input-dropdown";
import inputRadio from "@/components/common/input-radio";
import inputDate from "@/components/common/input-date";
import btnBig from "@/components/common/btn-big";

export default {
  name: "PersonalInformation",
  components: {
    inputText,
    inputDropdown,
    inputRadio,
    inputDate,
    btnBig,
  },
  props: {
    formData: Object,
    tab: Object,
    nextTab: Object,
  },
};
</script>

<template>
  <div class="contact-form">
    <div class="layout">
      <div class="section">
        <div id="contact-form-top"></div>

        <div v-if="formSent == false">
          <div class="title">
            <div class="txt">Neem telefonisch contact met mij op</div>
          </div>

          <div class="sep-70" v-show="contactForm.state == false"></div>
          <div
            class="error"
            v-show="contactForm.state == false"
          >Er zijn enkele velden niet correct ingevuld.</div>
          

          <div class="form" :class="{ formcheck: contactForm.state == false }">
            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.companyName.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.companyName"></input-text>
                </div>
              </div>
            </div>

            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.firstName.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.firstName"></input-text>
                </div>
              </div>
            </div>

            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.lastName.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.lastName"></input-text>
                </div>
              </div>
            </div>

            <div class="row-11-a radio-input">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.gender.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-radio v-model="contactForm.fields.gender">
                    <span slot="option1">Man</span>
                    <span slot="option2">Vrouw</span>
                  </input-radio>
                </div>
              </div>
            </div>

            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.phoneNumber.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.phoneNumber"></input-text>
                </div>
              </div>
            </div>

            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.mobilePhoneNumber.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.mobilePhoneNumber"></input-text>
                </div>
              </div>
            </div>

            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.emailAddress.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.emailAddress"></input-text>
                </div>
              </div>
            </div>

            <div class="row-11-a">
              <div class="col">
                <div class="txt-label">{{ contactForm.fields.comments.ui.label }}</div>
              </div>
              <div class="col">
                <div class="inputs">
                  <input-text v-model="contactForm.fields.comments"></input-text>
                </div>
              </div>
            </div>
          </div>

          <btn-big class="allGreen" :width="370" :height="92" :click="submitForm">
            <div class="content">
              <div class="txt">Gegevens verzenden</div>
              <img src="@/assets/arrow.svg" alt>
            </div>
          </btn-big>
        </div>

        <form-message v-else-if="formSent == true" :option="'FOOTER'"></form-message>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridRowGap: 2em;
  @gridColGap: 1.25em;

  .contact-form {
    background-color: getColor("greyLight");
    margin-top: pxToEm(-105);
    margin-bottom: pxToEm(50);

    .layout {
      max-width: pxToEm(900);

      .section {
        display: flex;
        flex-direction: column;
        margin: 0 pxToEm(50);
        padding: 10em 0 10em 0;
        box-sizing: border-box;

        #contact-form-top {
          position: relative;
          top: pxToEm(-250);
        }

        .title {
          .txt {
            font-size: pxToPercent(40);
            font-weight: 300;
          }
        }

        .error {
          color: getColor("redColor");
          font-style: italic;
        }

        .form {
          max-width: 800px;
          display: grid;
          grid-row-gap: @gridRowGap;
          margin-top: pxToEm(90);
          text-align: left;

          .input-text {
            width: pxToEm(360);
            height: pxToEm(60);
          }

          .txt-label {
            font-size: pxToPercent(18);
            font-weight: 300;
            line-height: 2.2em;
          }

          .row-11-a {
            height: pxToEm(70);
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: @gridColGap;
            align-items: center;
          }

          &:nth-child(3) {
            margin-bottom: 0;
          }
        }

        .btn-big {
          cursor: pointer;
          margin:0 auto;
          margin-top:pxToEm(100);

          .content {
            margin-left: pxToEm(20);
            margin-right: pxToEm(20);

            .txt {
              font-size: pxToPercent(20);
              font-weight: 700;
            }

            img {
              width: pxToEm(48);
              height: pxToEm(38);
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .btn-big {
    .content {
      .txt {
        font-size: pxToPercent(28);
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import inputText from "@/components/common/input-text";
  import inputRadio from "@/components/common/input-radio";
  import formMessage from "@/components/common/form-message";
  import { _partnershipForm } from "@/data/contactData.js";
  import { goToAnchor, checkTab } from "@/JS/helpers";
  import { dataToMail, mails } from "@/JS/mail";

  export default {
    name: "deskContactPartershipForm",
    components: {
      btnBig,
      inputText,
      inputRadio,
      formMessage
    },
    data: function() {
      return {
        contactForm: {
          fields: new _partnershipForm(),
          state: undefined
        },
        formSent: false
      };
    },
    methods: {
      submitForm() {
        this.contactForm.state = checkTab(this.contactForm.fields);
        if (this.contactForm.state == false) {
          // goToAnchor("#form-error");
        }
        else {
          dataToMail({
            mailTo: mails.info,
            mailSubject: "Contact - Partnership",
            mailData: this.contactForm.fields
          })
            .then(res => {
              //CODE FOR MAIL SENT /////////////
              // this.contactForm.fields = new _smallForm();
              // this.contactForm.state = undefined;
              this.formSent = true;
              goToAnchor("#contact-form-top");
              // console.log(res);
            })
            .catch(rej => {
              //CODE FOR MAIL NOT SENT /////////////
              this.formSent = false;
              // console.log(rej);
            });
        }
      }
    }
  };
</script>

import { render, staticRenderFns } from "./DesktopWorkWithUsSectionBlock.vue?vue&type=template&id=3730e851&scoped=true&"
import script from "./DesktopWorkWithUsSectionBlock.vue?vue&type=script&lang=js&"
export * from "./DesktopWorkWithUsSectionBlock.vue?vue&type=script&lang=js&"
import style0 from "./DesktopWorkWithUsSectionBlock.vue?vue&type=style&index=0&id=3730e851&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3730e851",
  null
  
)

export default component.exports
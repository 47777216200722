<template>
  <div class="contact-form">
    <div class="layout">
      <div class="section">

        <div class="message-container">
          <div id="contact-form-top" class="offerte-msg" v-show="contactForm.state == true">
            <div class="title">
              <div class="txt bold">Dank u wel!</div>
            </div>
            <div class="message">
              <div class="txt">
                Wij hebben uw aanvraag ontvangen.<br>
                Wij nemen z.s.m. contact met u op.
              </div>
            </div>
          </div>
        </div>
        
        <div class="container">
          <div class="error" id="form-error" v-show="contactForm.state == false">Er zijn enkele velden niet correct ingevuld.</div>
          
          <div class="form" v-if="contactForm.state !== true">
            <div class="expandable-items">
              <!--ITEM 1 ////////////////////////////////////////// -->
              <div id="form-error-tab1"></div>
              <div
                class="exp-item item1"
                :class="{ expanded: tabs.tab1.expand }"
                v-registerForm="true"
              >
                <car-information
                  :formData="contactForm.fields"
                  :tab="tabs.tab1"
                  :nextTab="tabs.tab2"
                  @toggle-tab="toggleTab"
                ></car-information>
              </div>

              <div class="sep-30"></div>

              <!--ITEM 2 ////////////////////////////////////////// -->
              <div id="form-error-tab2"></div>
              <div
                class="exp-item item2"
                :class="{ expanded: tabs.tab2.expand }"
                v-registerForm="true"
              >
                <personal-information
                  :formData="contactForm.fields"
                  :tab="tabs.tab2"
                  :nextTab="tabs.tab3"
                  @toggle-tab="toggleTab"
                ></personal-information>
              </div>

              <div class="sep-30"></div>

              <!--ITEM 3 ////////////////////////////////////////// -->
              <div id="form-error-tab3"></div>
              <div
                class="exp-item item3"
                :class="{ expanded: tabs.tab3.expand }"
                v-registerForm="true"
              >
                <contact-details
                  :formData="contactForm.fields"
                  :tab="tabs.tab3"
                  @toggle-tab="toggleTab"
                ></contact-details>
              </div>

              <div class="sep-50"></div>

              <btn-big class="primary" :width="393" :height="92" v-on:click="submitForm">
                <div class="content">
                  <div class="txt">Formulier verzenden</div>
                  <img src="@/assets/arrow.svg" alt />
                </div>
              </btn-big>

              <div class="sep-90"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;

.area {
  background-color: getColor("greyLight");

  .layout {
    max-width: 1200px;

    .section {

      .container {
        max-width: pxToEm(992);
        position: relative;
        margin: 0 auto;

        .bg-green{
          background-color: #65CE55;
          padding: pxToEm(20);
          color: white;
          font-size: pxToEm(20);
          font-weight: 700;
        }

        .text-success{
          color: #65CE55;
          font-size: pxToEm(20);
          font-weight: 700;
        }
        
        .text-error{
          color: #C82346;
          font-size: pxToEm(20);
          font-weight: 700;
        }

        .expandable-items {
          min-width: pxToEm(800);
          text-align: left;

          .exp-item {
            height: pxToEm(100);
            background-color: getColor("whiteColor");
            box-shadow: pxToEm(1) pxToEm(1) pxToEm(10) rgba(0, 0, 0, 0.2);

            &.expanded {
              height: auto;

              .exp-item-title-container {
                .right {
                  .arrow {
                    transform: rotate(-135deg);
                  }
                }
              }
            }

            .exp-item-subtitle {
              font-size: pxToPercent(24);
            }
          }

          .btn-big {
            margin-left: auto;

            .content {
              margin-left: pxToEm(30);
              margin-right: pxToEm(30);

              .txt {
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              img {
                width: pxToEm(48);
                height: pxToEm(38);
              }
            }
          }
        }
      }

      .message-container{
        max-width: pxToEm(625);
        position: relative;
        margin: 0 auto;

        .offerte-msg {
          padding: pxToEm(70) pxToEm(140);
          background-color: white;
          outline: 1px solid getColor("grayLtColor");
          box-shadow: 0 10px pxToEm(20) rgba(0, 0, 0, 0.2);
          margin-bottom: pxToEm(70);

          .title {
            margin-bottom: pxToEm(25);

            .txt {
              font-size: pxToEm(36);
              font-weight: 700;

              .mobile& {
                font-size: pxToEm(28);
              }
            }
          }

          .message {
            line-height: 2.8em;

            .txt {
              font-size: pxToEm(20);

              .mobile& {
                font-size: pxToPercent(17);
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import formMessage from "@/components/common/form-message";
import { _leaseCalculatorForm } from "@/data/contactData.js";
import { goToAnchor, checkTab } from "@/JS/helpers";
import { interestForFinancialLeaseDataToMail, mails } from "@/JS/mail";
import CarInformation from "@/components/desktop/interest-for-lease-calculator-form/CarInformation";
import PersonalInformation from "@/components/desktop/interest-for-lease-calculator-form/PersonalInformation";
import ContactDetails from "@/components/desktop/interest-for-lease-calculator-form/ContactDetails";

export default {
  name: "deskInterestForLeaseCalculatorForm",
  props: {
    email: String,
  },
  components: {
    btnBig,
    formMessage,
    CarInformation,
    PersonalInformation,
    ContactDetails
  },
  data: function () {
    return {
      contactForm: {
        fields: new _leaseCalculatorForm(),
        state: undefined
      },
      formSent: false,
      tabs: {
        tab1: {
          state: true,
          expand: true
        },
        tab2: {
          state: true,
          expand: false
        },
        tab3: {
          state: true,
          expand: false
        }
      },
    };
  },
  methods: {
    toggleTab(tabNumber) {
      this.tabs[`tab${tabNumber}`].expand = !this.tabs[`tab${tabNumber}`].expand;
    },

    submitForm() {
      this.contactForm.state = checkTab(this.contactForm.fields);
      if (this.contactForm.state == false) {
        goToAnchor("#form-error", 200);
      } else {
        interestForFinancialLeaseDataToMail({
          mailTo: this.email ? this.email : mails.info,
          mailSubject: "Contact - Interest for lease calculator",
          mailData: this.contactForm.fields
        }).then(res => {
          //CODE FOR MAIL SENT /////////////
          this.contactForm.fields = new _leaseCalculatorForm();
          this.contactForm.state = true;
          this.formSent = true;
          
          goToAnchor("#contact-form-top", 200);
        })
        .catch(rej => {
          //CODE FOR MAIL NOT SENT /////////////
          this.formSent = false;
          console.log(rej);
          goToAnchor("#form-error", 200);
        });
      }
    }
  }
};
</script>

<template>
  <div class="form-message" :class="{ mobile: $store.state.deviceState == $store.state.Device.MOBILE }">
    <div class="about-msg" v-if="origin[option] == origin.ABOUT">
      <!--
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47.386"
        height="50.386"
        viewBox="0 0 47.386 50.386"
      >
        <g transform="translate(-270.639 -2003.741)">
          <g transform="translate(270.639 2011.667)" fill="none" stroke="#65CE55" stroke-width="3">
            <ellipse cx="21.23" cy="21.23" rx="21.23" ry="21.23" stroke="none"></ellipse>
            <ellipse cx="21.23" cy="21.23" rx="19.73" ry="19.73" fill="none"></ellipse>
          </g>
          <path
            d="M36.474,13.708,0,16.424.11,0"
            transform="translate(279.398 2029.618) rotate(-45)"
            fill="none"
            stroke="#65CE55"
            stroke-width="3"
          ></path>
        </g>
      </svg>
      -->

      <div class="message">
        <div class="txt">
          De gegevens zijn verzonden.<br>
          We nemen zo snel mogelijk contact met u op.
        </div>
      </div>
    </div>

    <div class="footer-msg" v-else-if="origin[option] == origin.FOOTER">
      <div class="message">
        <div class="txt">
          Uw gegevens zijn verzonden.<br>
          We nemen zo snel mogelijk contact met u op.
        </div>
      </div>
    </div>

    <div class="offerte-msg" v-else-if="origin[option] == origin.OFFERTE && $store.state.loanException">
      <div class="title">
        <div class="txt bold">{{$store.state.loanException.title}}</div>
      </div>
      <div class="message">
        <div class="txt">
          {{$store.state.loanException.message}}
        </div>
      </div>
    </div>

    <div class="offerte-msg" v-else-if="origin[option] == origin.OFFERTE">
      <div class="title">
        <div class="txt bold">Dank u wel!</div>
      </div>
      <div class="message">
        <div class="txt">
          Wij hebben uw aanvraag ontvangen.<br>
          Wij nemen z.s.m. contact met u op.
        </div>
      </div>
    </div>

    <div class="contact-msg" v-else-if="origin[option] == origin.CONTACT">
      <div class="message">
        <div class="txt">
          Uw gegevens zijn verzonden. We nemen zo snel mogelijk contact met u op.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .form-message {
    max-width: pxToEm(964);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;

    .about-msg {
      svg {
        width: pxToEm(66);
        height: pxToEm(72);

        .mobile& {
          width: pxToEm(53);
          height: pxToEm(58);
        }
      }

      .message {
        .txt {
          font-size: pxToPercent(20);

          .mobile& {
            font-size: pxToPercent(17);
          }
        }
      }
    }

    .footer-msg {
      max-width: pxToEm(512);
      line-height: 2.8em;

      .mobile& {
        line-height: 2em;
      }

      .message {
        .txt {
          font-size: pxToPercent(20);

          .mobile& {
            font-size: pxToPercent(17);
          }
        }
      }
    }

    .offerte-msg {
      padding: pxToEm(70) pxToEm(140);
      background-color: white;
      outline: 1px solid getColor("grayLtColor");
      box-shadow: 0 10px pxToEm(20) rgba(0, 0, 0, 0.2);

      .mobile& {
        padding: pxToEm(60) pxToEm(40);
      }

      .title {
        margin-bottom: pxToEm(25);

        .txt {
          font-size: pxToEm(36);
          font-weight: 700;

          .mobile& {
            font-size: pxToEm(28);
          }
        }
      }

      .message {
        line-height: 2.8em;

        .mobile& {
          line-height: 2em;
        }

        .txt {
          font-size: pxToEm(20);

          .mobile& {
            font-size: pxToPercent(17);
          }
        }
      }

      .btn-big {
        border-bottom-width: pxToEm(18);
        text-align: left;
        margin-top: pxToEm(60);

        .content {
          margin-left: pxToEm(30);
          margin-right: pxToEm(30);

          .txt {
            font-size: pxToPercent(20);
            font-weight: 700;
          }

          img {
            width: pxToEm(48);
            height: pxToEm(38);
          }
        }
      }
    }

    .contact-msg {
      padding: pxToEm(90);
      align-self: flex-start;
      text-align: left;
      line-height: 2.8em;

      .mobile& {
        line-height: 2em;
        padding: pxToEm(70) pxToEm(50);
      }

      .message {
        .txt {
          font-size: pxToPercent(20);

          .mobile& {
            font-size: pxToPercent(17);
          }
        }
      }
    }
  }
</style>

<script>
  export default {
    name: "formMessage",
    props: {
      option: {
        type: String,
        default: "ABOUT"
      }
    },
    data() {
      return {
        origin: {
          ABOUT: 0,
          FOOTER: 1,
          OFFERTE: 2,
          CONTACT: 3
        }
      };
    }
  };
</script>

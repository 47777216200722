<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div id="anchor-top-contact"></div>

      <div class="section">
        <div class="container-a">

          <div class="title">
            <div class="txt">{{block.Title}}</div>
          </div>

          <div class="contact">
            <div class="txt" v-html="subtitle_html"></div>
          </div>
        </div>

        <div id="contact-form-top"></div>

        <btn-big class="secondary" :width="234" :height="74" :click="() => toggleContact(1)"
                 v-anchor="'#contact-form-top'">
          <div class="content">
            <div class="txt">
              Neem contact
              <br>op met mij
            </div>
            <img src="@/assets/arrow.svg" alt>
          </div>
        </btn-big>
      </div>

      <contact-form v-if="contactActive==1"></contact-form>

      <a href="#anchor-top">
        <div class="white-button" v-if="contactActive" @click="toggleContact(0)">
          <div class="arrow-cont">
            <div class="arrow"></div>
          </div>
        </div>
      </a>
    </div>

    <div class="layout">
      <div class="section">
        <div class="icons-container">
          <div class="icons">
            <img src="@/assets/phone.svg">
            <div class="txt">
              <a href="tel:023 539 5926">023 539 5926</a>
            </div>

            <img src="@/assets/email.svg">
            <div class="txt">
              <a href="mailto:info@leenattent.nl">info@leenattent.nl</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    .section {
      margin: pxToEm(25) pxToEm(20) 0;

      .container-a {
        padding: pxToEm(25) pxToEm(15) 0;

        .title {
          font-size: pxToPercent(40);
          font-weight: 300;
        }

        .contact {
          margin-top: pxToEm(30);

          .txt {
            font-size: pxToPercent(16);
            line-height: 2.3em;

            span {
              text-decoration: underline;
            }
          }
        }
      }

      #contact-form-top {
        position: relative;
        top: pxToEm(10);
      }

      .btn-big {
        margin: pxToEm(40) auto 0;

        .content {
          margin: 0 pxToEm(20);

          .txt {
            font-size: pxToPercent(16);
            font-weight: 700;
            text-align: left;
          }

          img {
            width: pxToEm(36);
            height: pxToEm(28);
          }
        }
      }

      .icons-container {
        margin-top: pxToEm(40);
        margin-bottom: pxToEm(70);
        display: flex;
        flex-direction: column;
        align-items: center;

        .icons {
          display: grid;
          grid-template-columns: pxToEm(36) 1fr;
          grid-row-gap: 2.5em;
          grid-column-gap: pxToEm(30);

          img {
            width: pxToEm(36);
            height: pxToEm(36);
          }

          .txt {
            font-size: pxToEm(18);
            font-weight: 600;
            align-self: center;
            text-align: left;
            color: getColor("greenDefault");

            a {
              color: getColor("greenDefault");
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .white-button {
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    left: 50%;
    bottom: pxToEm(-25);
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: getColor("whiteColor");
    box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
    z-index: 100;
    cursor: pointer;

    .arrow-cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        position: relative;
        margin-top: pxToEm(5);
        padding: pxToEm(5);
        transform: rotate(-135deg);
        display: inline-block;
        border: solid getColor("greenDefault");
        border-width: 0 pxToEm(3) pxToEm(3) 0;
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import contactForm from "@/components/mobile/mob-contact-partnership-form";
import {marked} from "marked";

export default {
  name: 'MobileContactSectionBlock',
  props: {
    block: Object
  },
  components: {
    btnBig,
    contactForm
  },
  data() {
    return {
      contactActive: 0
    };
  },
  methods: {
    toggleContact(val) {
      this.contactActive = val;
    }
  },
  computed: {
    subtitle_html: function () {
      return marked(this.block.Subtitle);
    }
  }
}
</script>

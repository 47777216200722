<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <h1 class="title">{{block.Title}}</h1>
        <div class="pink"></div>

        <div class="dropdown-container">
          <div class="introtitle" v-if="block.Introtitle">{{block.Introtitle}}</div>

          <div class="container">

            <div id="form-top-custom"></div>

            <div
              class="txt"
              v-if="block.Subtitle"
            >{{block.Subtitle}}</div>
          </div>
        </div>

        <!-- <div class="contact txt" v-html="subtitle_html">
        </div> -->

      </div>
    </div>

    <contact-form :email="block.email"></contact-form>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  min-height: pxToEm(500); //828
  display: flex;
  flex-direction: column;

  &.white {
    background-color: #fff;
  }

  .layout {
    .section {
      margin: pxToEm(50);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .input {
        height: pxToEm(62);
      }

      .title {
        font-size: pxToPercent(70);
        font-weight: 800;
        margin-top: 0;
        margin-bottom: 0;
      }

      .pink {
        width: 123px;
        height: 11px;
        background-color: getColor("primaryLight");
      }

      .dropdown-container {
        display: flex;
        flex-direction: column;
        max-width: 964px;
        margin-top: pxToEm(50);
        width: 100%;

        .introtitle {
          font-size: 150%;
          font-weight: 700;
        }

        .container {
          padding: pxToEm(20) pxToEm(90) pxToEm(20);

          .expandable-items {
            min-width: pxToEm(800);
            text-align: left;

            .exp-item {
              height: pxToEm(100);
              background-color: getColor("whiteColor");
              box-shadow: pxToEm(1) pxToEm(1) pxToEm(10) rgba(0, 0, 0, 0.2);

              &.expanded {
                height: auto;

                .exp-item-title-container {
                  .right {
                    .arrow {
                      transform: rotate(-135deg);
                    }
                  }
                }
              }

              .exp-item-subtitle {
                font-size: pxToPercent(24);
              }
            }
          }

          .txt {
            font-size: pxToPercent(20);
            line-height: 2;
          }

          .title {
            font-size: 225%;
            font-weight: 300;
            margin-bottom: pxToEm(30);
          }

          .select {
            position: absolute;
            width: pxToEm(500);
            transform: translate(-50%);
            left: 50%;
            z-index: 200;
          }
        }
      }

      .btn-big {
        width: pxToEm(400);
        height: pxToEm(104);
        margin: pxToEm(55) auto;
        cursor: pointer;

        .content {
          margin-left: pxToEm(40);
          margin-right: pxToEm(40);
          .txt {
            font-size: pxToPercent(21);
            font-weight: 700;
          }
          img {
            width: pxToEm(56);
            height: pxToEm(45);
          }
        }
      }
    }
  }
  
  .white-button {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    bottom: 245px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    .arrow-cont {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .arrow {
        border: solid getColor("greenDefault");
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-135deg);
        position: relative;
        margin-top: 5px;
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import contactForm from "@/components/desktop/desk-interest-for-lease-calculator-form.vue";
import { goToAnchor, checkTab } from "@/JS/helpers";
import {marked} from 'marked'

export default {
  name: 'DesktopInterestForFinancialLease',
  components: {
    btnBig,
    contactForm,
  },
  props: {
    block: Object
  },
  computed: {
    subtitle_html: function () {
      return marked(this.block.Subtitle);
    }
  }
}
</script>

<template>
  <div class="contact-form">

    <div class="sep-70" v-show="calculatorForm.state == false"></div>

    <div class="form" :class="{ formcheck: calculatorForm.state == false }">

      <div class="desktop" v-if="!isMobile">
        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">{{ calculatorForm.fields.credit_amount.ui.label }}</div>
          </div>
          <div class="col inputs">
            <input-num v-model="calculatorForm.fields.credit_amount" :blur="calculateResult()"></input-num>
          </div>
        </div>

        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">{{ calculatorForm.fields.duration.ui.label }}</div>
          </div>
          <div class="col inputs">
            <input-dropdown
              :options="calculatorForm.fields.duration.ui.options"
              v-model="calculatorForm.fields.duration"
              :change="calculateResult()"
              placeholder="Maak uw keuze"
            >
            </input-dropdown>
          </div>
        </div>
        
        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">{{ calculatorForm.fields.final_term.ui.label }}</div>
          </div>
          <div class="col inputs">
            <input-num
              v-model="calculatorForm.fields.final_term"
              :blur="calculateResult()"
            >
            </input-num>
          </div>
        </div>
        
        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">Maandtermijn obv tarief</div>
          </div>
          <div class="col inputs">
            <div class="result"><strong>&euro; {{ installment ? installment : 0 }}</strong></div>
          </div>
        </div>
      </div>
      
      <div class="mobile" v-if="isMobile">
        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">{{ calculatorForm.fields.credit_amount.ui.label }}</div>
          </div>
          <div class="col inputs">
            <input-num v-model="calculatorForm.fields.credit_amount" :blur="calculateResult()"></input-num>
          </div>
        </div>

        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">{{ calculatorForm.fields.duration.ui.label }}</div>
          </div>
          <div class="col inputs">
            <input-dropdown
              :options="calculatorForm.fields.duration.ui.options"
              v-model="calculatorForm.fields.duration"
              :change="calculateResult()"
              placeholder="Maak uw keuze"
            >
            </input-dropdown>
          </div>
        </div>
        
        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">{{ calculatorForm.fields.final_term.ui.label }}</div>
          </div>
          <div class="col inputs">
            <input-num
              v-model="calculatorForm.fields.final_term"
              :blur="calculateResult()"
            >
            </input-num>
          </div>
        </div>
        
        <div class="row-11-b text-input">
          <div class="col">
            <div class="txt-label">Maandtermijn obv tarief</div>
          </div>
          <div class="col inputs">
            <div class="result"><strong>&euro; {{ installment ? installment : 0 }}</strong></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;
  .desktop{
    .row-11-b {
      height: pxToEm(100);
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      .col {
        &:first-child {
          margin-right: @gridColHalfGap;
        }

        &:last-child {
          margin-left: @gridColHalfGap;
          margin-right: 0;
        }

        .result{
          text-align: center;

          strong{
            font-size: 26px;
          }
        }
      }

      .input-text-300 {
        width: pxToEm(296);
      }
    }
  }

  .mobile {
    .row-11-b {
      height: pxToEm(100);
      text-align: left;

      &:first-child {
        margin-top: @gridRowGap;
      }

      .txt-label{
        color: #FFF;
      }

      .result{
        strong{
          font-size: 26px;
          color: #FFF;
        }
      }
    }
  }
</style>

<script>
  import { _carLeaseCalculatorForm } from "@/data/contactData.js";
  import inputText from "@/components/common/input-text";
  import inputNum from "@/components/common/input-num";
  import inputDropdown from "@/components/common/input-dropdown";
  
  export default {
    props: {
      commissionPercentage: 0,
      paymentPerMonth: 'Advanced',
      subTitle: '',
      installment: 0,
    },
    name: "leaseCalculatorForm",
    components: {
      inputText,
      inputNum,
      inputDropdown,
    },
    data: function() {
      return {
        calculatorForm: {
          fields: new _carLeaseCalculatorForm(),
          state: undefined
        },
        formSent: false
      };
    },
    computed: {
      isMobile: function ({$store}) {
        return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
      }
    },
    methods: {
      pmt(rate, nper, pv, fv = 0, type = 0) {
        if (rate === 0) {
          return -(pv + fv) / nper;
        }
        
        const pvif = Math.pow(1 + rate, nper);
        let pmt = rate / (pvif - 1) * -(pv * pvif + fv);

        if (type === 1) {
          pmt /= (1 + rate);
        }

        return pmt.toFixed(2)
      },

      calculateResult() {
        let creditAmount = parseFloat(this.calculatorForm.fields.credit_amount);
        let commission = creditAmount * parseFloat(this.commissionPercentage) / 100;
        let durationIndex = parseInt(this.calculatorForm.fields.duration);
        let _duration = this.calculatorForm.fields.duration.ui.options[durationIndex];
        let duration = parseInt(_duration ? _duration.split(" ")[0] : 0);
        
        let finalTerm = parseFloat(this.calculatorForm.fields.final_term);
        let type = this.paymentPerMonth === 'Advanced' ? 1 : 0;
        
        finalTerm = finalTerm ? finalTerm : 0;
        // this.installment = this.pmt(10.99 / 1200, 36, -7000 - 700, 1000, 1); // 226.40
        if (creditAmount > 0 && !isNaN(commission) && duration > 0) {
          this.installment = this.pmt(10.99 / 1200, duration, - creditAmount - commission, finalTerm, type);
        } else {
          this.installment = creditAmount;
          console.log(finalTerm, this.commissionPercentage, duration);
        }
      },
    }
  };
</script>
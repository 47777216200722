function getComponentsInFolder(list) {
  var result = {};

  list.keys().forEach(el => {
    var name = el.replace(/\.\/|.vue/g, "").replace(/-[a-z]/gi, function (m) {
      return m[1].toUpperCase();
    });
    result[name] = list(el).default;
  });

  return result;
}

function strapiAssetUrl(path) {
  return (process.env.VUE_APP_ASSET_URL || 'https://la-api.thewiseminds.com/') + path;
}

function assignSet() {
  if (typeof Object.assignSet != 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assignSet", {
      value: function assignSet(target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null && nextSource != undefined) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                if (nextSource[nextKey] != null)
                  to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }
}

function goToAnchor(id, offset) {
  var $anchor,
    $scrollContainer;

  $anchor = document.querySelector(id);
  if ($anchor == null)
    return;

  if(!offset){
    offset = 0;
  }
  $scrollContainer = document.querySelector('.scroll-container');
  offset = $scrollContainer.scrollTop + $anchor.getBoundingClientRect().top - offset;
  // $scrollContainer.scrollTop = offset;

  $scrollContainer.scrollTo({
    behavior: 'smooth',
    top: offset
  });

  // $anchor.scrollIntoView({
  //   behavior: 'smooth'
  // });
}

function checkException(tabs) {
  let errorTitle, errorMessage;

  const married = tabs.tab3.origin.married.valueOf();
  const income = tabs.tab3.origin.income.valueOf();
  const incomeSource = tabs.tab3.origin.incomeSource.valueOf(); // income source 13 == ww
  const partnerIncome = tabs.tab3.origin.partnerIncome.valueOf();  // income source 13 == ww
  const partnerIncomeSource = tabs.tab3.origin.partnerIncomeSource.valueOf();

  if (incomeSource === 13 && ! married) {
    errorTitle = 'Helaas kunnen wij u niet van dienst zijn!'
    errorMessage = 'De met ons samenwerkende banken nemen uw inkomen uit een WW uitkering niet mee!'
  } else if (incomeSource === 13 && married && partnerIncome < 3000 || married && partnerIncomeSource === 13 && income < 3000) {
    errorTitle = 'Helaas kunnen wij u niet van dienst zijn!'
    errorMessage = 'De met ons samenwerkende banken nemen uw inkomen uit een WW uitkering niet mee en daardoor is de lening niet betaalbaar op uitsluitend het inkomen van uw partner'
  } if (incomeSource === 14 && ! married) {
    errorTitle = 'Helaas kunnen wij u niet van dienst zijn!'
    errorMessage = 'De met ons samenwerkende banken nemen uw inkomen uit een Bijstandsuitkering niet mee!'
  } else if (incomeSource === 14 && married && partnerIncome < 3000 || married && partnerIncomeSource === 14 && income < 3000) {
    errorTitle = 'Helaas kunnen wij u niet van dienst zijn!'
    errorMessage = 'De met ons samenwerkende banken nemen uw inkomen uit een Bijstandsuitkering niet mee en daardoor is de lening niet betaalbaar op uitsluitend het inkomen van uw partner'
  }

  if (errorTitle || errorMessage) {
    return {
      title: errorTitle,
      message: errorMessage
    }
  }

  return false;
}

function checkTab(tab, origin) {
  var res = true;
  for (var prop in tab) {
    var input = origin ? origin[tab[prop]] : tab[prop];

    if (prop == "ui") {
      return res;
    }

    if (input instanceof Array || input instanceof Object && input.ui == undefined) {
      /** need to check if prevloans is empty. Otherwise, since it's an array it will
       * try to enter and it gives undefined
       */
      if (input.length != 0) res = checkTab(input);
      else res = false;
    } else {
      if (input.ui.valid == false && input.ui.visible == true) {
        res = false;
        return res;
      }
    }
  }
  return res;
}

export {
  getComponentsInFolder,
  assignSet,
  goToAnchor,
  checkTab,
  checkException,
  strapiAssetUrl
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.block)?_c('div',{staticClass:"area"},[_c('div',{staticStyle:{"width":"100%","height":"45px","background-color":"white","position":"absolute"}}),_c('div',{staticClass:"layout"},[(
        _vm.block.Image &&
        _vm.block.Image.data &&
        _vm.block.Image.data.attributes &&
        _vm.block.Image.data.attributes.url
      )?_c('div',{staticClass:"pic",style:({
        'background-image':
          'url(' + _vm.strapiAssetUrl(_vm.block.Image.data.attributes.url) + ')',
      })}):_vm._e(),_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.block.Title))])]),_c('div',{staticClass:"subtitle"},[_c('span',[_vm._v(_vm._s(_vm.block.Subtitle))])]),_c('calculator-form',{staticClass:"bg-white",attrs:{"commissionPercentage":_vm.block.CommissionPercentage,"paymentPerMonth":_vm.block.PaymentPerMonth,"subTitle":_vm.block.Subtitle}}),_c('div',{staticClass:"btn-container",on:{"click":_vm.goToRoute}},[_c('btn-big',{staticClass:"primary",attrs:{"height":68}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"txt"},[_vm._v("Financial lease aanvragen")]),_c('img',{attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])])],1)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div id="form-top-custom"></div>

    <div class="layout">
      <div class="sep-50"></div>

      <div v-if="$store.state.formSent == false">
        <div class="section">
          <div class="container">
            <div class="expandable-items">
              <!--ITEM 1 ////////////////////////////////////////// -->
              <div id="form-error-tab1" class="anchor-error"></div>
                <div
                  class="exp-item item1"
                  :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[0].expand : false }"
                  v-registerForm="true"
                >
                <div :class="{ formcheck: tabs.tab1.state == false }">
                  <div class="exp-item-title-container">
                    <div class="exp-item-title" :class="{ valid: tabs.tab1.state == true }">
                      <div class="left">
                        <span>1. Uw gewenste lening</span>
                      </div>

                      <div class="right">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>

                  <div class="exp-item-text-container">
                    <div
                      class="error"
                      v-if="tabs.tab1.state == false"
                      style="white-space: pre-line"
                    >{{ $store.state.tabErrors.tab1 }}</div>

                    <div class="exp-item-text">
                      <div class="exp-item-body">
                        <div class="col-11 select-input">
                          <div class="txt-label">
                            <span>Waarvoor wilt u geld lenen?</span>
                          </div>
                          <input-dropdown
                            style="z-index: 230;"
                            :options="$store.state.funnelOptions"
                            v-model="Funnel"
                          ></input-dropdown>
                        </div>

                        <div
                          class="col-11 text-input"
                        >
                          <div class="txt-label">
                            <span>Hoeveel geld wilt u lenen?</span>
                          </div>

                          <input-num v-model="loanRequest.approvedLoanAmount"></input-num>
                        </div>

                        <div class="col-11 select-input">
                          <div class="txt-label">
                            <span>In welk kredietvorm wilt u de lening afsluiten?</span>
                          </div>
                          <input-dropdown
                            style="z-index: 220;"
                            :options="$store.state.loanTypeOptions"
                            v-model="loanRequest.loanType"
                          ></input-dropdown>
                        </div>

                        <div v-if="showSelectedMonths" class="col-11 select-input">
                          <div class="txt-label">
                            <span>Doorlooptijd in maanden</span>
                          </div>
                          <input-dropdown
                            style="z-index: 210;"
                            :options="loanInfo[$store.state._loanRequest.loanTypeTable].months"
                            v-model="loanRequest.months"
                          ></input-dropdown>
                        </div>
                      </div>

                      <div class="sep-50"></div>

                      <btn-big
                        class="next secondary-pink"
                        style="margin-left: auto;"
                        :width="180"
                        :height="60"
                        v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[getTabs == 5 ? 1 : 2].expand : false"
                      >
                        <div class="content">
                          <div class="txt">Volgende</div>
                          <img src="@/assets/arrow.svg">
                        </div>
                      </btn-big>

                      <div class="sep-50"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!--ITEM 2 ////////////////////////////////////////// -->
              <div id="form-error-tab2" class="anchor-error"></div>
                <div
                  class="exp-item item2"
                  :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[1].expand : false }"
                  v-show="loanRequest.selectedFunnel == $store.state.funnel.B"
                  v-registerForm="tabs.tab2.state == false || ($store.state.storedData == true && tabs.tab2.state == undefined)"
                >
                <div :class="{ formcheck: tabs.tab2.state == false }">
                  <div class="exp-item-title-container">
                    <div class="exp-item-title" :class="{ valid: tabs.tab2.state == true }">
                      <div class="left">
                        <span>{{ getTabs - 3 }}. Huidige leningen</span>
                      </div>

                      <div class="right">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>

                  <div class="exp-item-text-container">
                    <div
                      class="error"
                      v-if="tabs.tab2.state == false"
                      style="white-space: pre-line"
                    >{{ $store.state.tabErrors.tab2 }}</div>

                    <div class="exp-item-text">
                      <div class="exp-item-body">
                        <div class="col-11 select-input">
                          <div class="txt-label">
                            <span>Hoeveel leningen heeft u lopen?</span>
                          </div>
                          <input-dropdown
                            :init="true"
                            :options="$store.state.loansDropdown"
                            v-model="Loans"
                          ></input-dropdown>
                        </div>
                      </div>

                      <div class="loans" v-if="Loans > 0">
                        <div
                          class="loan"
                          v-for="(loan, index) in loanRequest.prevLoans"
                          :key="index"
                        >
                          <div class="sub-title">Lening {{ index + 1 }}</div>

                          <div class="exp-item-body">
                            <div class="col-11 text-input">
                              <div class="txt-label">
                                <span>Maatschappij</span>
                              </div>
                              <input-text :init="loan.name.ui.init" v-model="loan.name"></input-text>
                            </div>

                            <div class="col-11 num-input">
                              <div class="txt-label">
                                <span>Openstaand bedrag</span>
                              </div>
                              <input-num :blur="calculateSaving(loan.amount, loan.rate, loan.name, index)" v-model="loan.amount"></input-num>
                            </div>

                            <div class="col-11 num-input">
                              <div class="txt-label">
                                <span>Effectieve jaarrente</span>
                              </div>
                              <input-num :blur="calculateSaving(loan.amount, loan.rate, loan.name, index)" v-model="loan.rate"></input-num>
                            </div>

                            <div class="col-11 toggle-input">
                              <div class="txt-label">
                                <span>Inlossen</span>
                              </div>
                              <input-boolean :init="loan.ransom.ui.init" v-model="loan.ransom"></input-boolean>
                            </div>

                            <p v-if="saveAmount[index]">{{ saveAmount[index] }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="sep-50"></div>

                      <btn-big
                        class="next secondary-pink"
                        style="margin-left: auto;"
                        :width="180"
                        :height="60"
                        v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[2].expand : false"
                      >
                        <div class="content">
                          <div class="txt">Volgende</div>
                          <img src="@/assets/arrow.svg">
                        </div>
                      </btn-big>

                      <div class="sep-50"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!--ITEM 3 ////////////////////////////////////////// -->
              <div id="form-error-tab3" class="anchor-error"></div>
                <div
                  class="exp-item item3"
                  :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[2].expand : false }"
                  v-registerForm="tabs.tab3.state == false || ($store.state.storedData == true && tabs.tab3.state == undefined)"
                >
                <div :class="{ formcheck: tabs.tab3.state == false }">
                  <div class="exp-item-title-container">
                    <div class="exp-item-title" :class="{ valid: tabs.tab3.state == true }">
                      <div class="left">
                        <span>{{ getTabs - 2 }}. Uw situatie</span>
                      </div>

                      <div class="right">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>

                  <div class="exp-item-text-container">
                    <div
                      class="error"
                      v-if="tabs.tab3.state == false"
                      style="white-space: pre-line"
                    >{{ $store.state.tabErrors.tab3 }}</div>

                    <div class="exp-item-text">
                      <div class="exp-item-body">
                        <div class="col-11 toggle-input">
                          <div class="txt-label">
                            <span>Bent u getrouwd of samenwonend?</span>
                          </div>
                          <input-boolean v-model="person.married"></input-boolean>
                        </div>

                        <div class="col-11 select-input">
                          <div class="txt-label">Wat is uw woonsituatie?</div>
                          <input-dropdown
                            :options="$store.state.livingStateDropdown"
                            v-model="person.livingState"
                          ></input-dropdown>
                        </div>

                        <div class="col-11 toggle-input">
                          <div class="txt-label">Heeft u inwonende kinderen?</div>
                          <input-boolean v-model="person.children"></input-boolean>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">
                            <span>Wat zijn uw woonlasten?</span>
                          </div>
                          <input-num v-model="person.monthlyExpenses"></input-num>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">
                            <span>Wat is uw netto inkomen per maand?</span>
                          </div>
                          <input-num :min=1 v-model="person.income"></input-num>
                        </div>

                        <div class="col-11 select-input">
                          <div class="txt-label">
                            <span>Wat is uw inkomstenbron?</span>
                          </div>
                          <div class="info-row" style="width: 100%">
                            <input-dropdown
                              :options="$store.state.incomeSourceDropdown"
                              v-model="person.incomeSource"
                            ></input-dropdown>

                            <div class="info-container">
                              <div class="info-icon" @click="toggleModal">
                                <div class="bg">
                                  <img src="@/assets/info.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-11 date-input">
                          <div class="txt-label">
                            <span>Datum in dienst?</span>
                          </div>
                          <input-date v-model="person.dateService"></input-date>
                        </div>
                      </div>

                      <div class="sep-50"></div>

                      <btn-big
                        class="next secondary-pink"
                        style="margin-left: auto;"
                        :width="180"
                        :height="60"
                        v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[3].expand : false"
                      >
                        <div class="content">
                          <div class="txt">Volgende</div>
                          <img src="@/assets/arrow.svg">
                        </div>
                      </btn-big>

                      <div class="sep-50"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="info-anchor">
          <div class="modal-container" v-show="$store.state.modal">
            <modal></modal>
          </div>
        </div>

        <div class="section">
          <div class="container">
            <div class="expandable-items">
              <!--ITEM 4 ////////////////////////////////////////// -->
              <div id="form-error-tab4" class="anchor-error"></div>
                <div
                  class="exp-item item4"
                  :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[3].expand : false }"
                  v-registerForm="tabs.tab4.state == false || ($store.state.storedData == true && tabs.tab4.state == undefined)"
                >
                <div :class="{ formcheck: tabs.tab4.state == false }">
                  <div class="exp-item-title-container">
                    <div class="exp-item-title" :class="{ valid: tabs.tab4.state == true }">
                      <div class="left">
                        <span>{{ getTabs - 1 }}. Uw persoonlijke gegevens</span>
                      </div>
                      <div class="right">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>

                  <div class="exp-item-text-container">
                    <div
                      class="error"
                      v-if="tabs.tab4.state == false"
                      style="white-space: pre-line"
                    >{{ $store.state.tabErrors.tab4 }}</div>

                    <div class="exp-item-text">
                      <div class="exp-item-body">
                        <div class="col-11 text-input">
                          <div class="txt-label">Voorletter(s)</div>
                          <input-text v-model="person.firstName"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Tussenvoegsel</div>
                          <input-text v-model="person.middleName"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Achternaam</div>
                          <input-text v-model="person.lastName"></input-text>
                        </div>

                        <div class="col-11 radio-input">
                          <div class="txt-label">Geslacht</div>
                          <input-radio v-model="person.gender">
                            <span slot="option1">Man</span>
                            <span slot="option2">Vrouw</span>
                          </input-radio>
                        </div>

                        <div class="col-11 date-input">
                          <div class="txt-label">Geboortedatum</div>
                          <input-date v-model="person.birthDate"></input-date>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Straat</div>
                          <input-text v-model="person.streetName"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Huisnummer</div>
                          <input-text v-model="person.houseNumber"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Toevoeging</div>
                          <input-text v-model="person.affix"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Postcode</div>
                          <input-text v-model="person.postCode"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Plaats</div>
                          <input-text v-model="person.city"></input-text>
                        </div>
                      </div>
                    </div>

                    <div class="exp-item-text" v-if="person.married == true">
                      <div class="sep-40"></div>

                      <div class="exp-item-subtitle">Gegevens van uw partner</div>

                      <div class="sep-30"></div>

                      <div class="exp-item-body">
                        <div class="col-11 text-input">
                          <div class="txt-label">Voorletter(s)</div>
                          <input-text v-model="person.partnerFirstName"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Tussenvoegsel</div>
                          <input-text v-model="person.partnerMiddleName"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Achternaam</div>
                          <input-text v-model="person.partnerLastName"></input-text>
                        </div>

                        <div class="col-11 radio-input">
                          <div class="txt-label">Geslacht</div>
                          <input-radio v-model="person.partnerGender">
                            <span slot="option1">Man</span>
                            <span slot="option2">Vrouw</span>
                          </input-radio>
                        </div>

                        <div class="col-11 date-input">
                          <div class="txt-label">Geboortedatum</div>
                          <input-date v-model="person.partnerBirthDate"></input-date>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">
                            <span>Wat is het netto inkomen per maand van uw partner?</span>
                          </div>

                          <input-num v-model="person.partnerIncome"></input-num>
                        </div>

                        <div class="col-11 select-input">
                          <div class="txt-label">
                            <span>Wat is uw inkomstenbron?</span>
                          </div>
                          <div class="info-row" style="width: 100%">
                            <input-dropdown
                              :options="$store.state.incomeSourceDropdown"
                              v-model="person.partnerIncomeSource"
                            ></input-dropdown>

                            <div class="info-container">
                              <div class="info-icon" @click="toggleModal">
                                <div class="bg">
                                  <img src="@/assets/info.png" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-11 date-input">
                          <div class="txt-label">
                            <span>Datum in dienst?</span>
                          </div>
                          <input-date v-model="person.partnerDateService"></input-date>
                        </div>

                      </div>
                    </div>

                    <div class="exp-item-text">
                      <div class="sep-50"></div>

                      <btn-big
                        class="next secondary-pink"
                        style="margin-left: auto;"
                        :width="180"
                        :height="60"
                        v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[4].expand : false"
                      >
                        <div class="content">
                          <div class="txt">Volgende</div>
                          <img src="@/assets/arrow.svg">
                        </div>
                      </btn-big>

                      <div class="sep-50"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!--ITEM 5 ////////////////////////////////////////// -->
              <div id="form-error-tab5" class="anchor-error"></div>
                <div
                  class="exp-item item5"
                  :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[4].expand : false }"
                  v-registerForm="tabs.tab5.state == false || ($store.state.storedData == true && tabs.tab5.state == undefined)"
                >
                <div :class="{ formcheck: tabs.tab5.state == false }">
                  <div class="exp-item-title-container">
                    <div class="exp-item-title" :class="{ valid: tabs.tab5.state == true }">
                      <div class="left">
                        <span>{{ getTabs }}. Uw contactgegevens</span>
                      </div>

                      <div class="right">
                        <div class="arrow"></div>
                      </div>
                    </div>
                  </div>

                  <div class="exp-item-text-container">
                    <div
                      class="error"
                      v-if="tabs.tab5.state == false"
                      style="white-space: pre-line"
                    >{{ $store.state.tabErrors.tab5 }}</div>

                    <div class="exp-item-text">
                      <div class="exp-item-body">
                        <div class="col-11 text-input">
                          <div class="txt-label">E-mail</div>
                          <input-text v-model="person.emailAddress"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Vaste telefoonnummer (Optioneel)</div>
                          <input-text v-model="person.fixedPhoneNumber"></input-text>
                        </div>

                        <div class="col-11 text-input">
                          <div class="txt-label">Mobiele telefoonnummer</div>
                          <input-text v-model="person.mobilePhoneNumber"></input-text>
                        </div>

                        <div class="col-11 textarea-input">
                          <div class="txt-label">Opmerkingen (Optioneel)</div>
                          <textarea v-model="person.comments"></textarea>
                        </div>
                      </div>

                      <div class="sep-50"></div>
                    </div>
                  </div>
                </div>
              </div>

              <btn-big id="submit-offer" class="primary" :height="86" :click="submitForm">
                <div class="content">
                  <div class="txt">Formulier verzenden</div>
                  <img src="@/assets/arrow.svg" alt />
                </div>
              </btn-big>

              <div class="agreement">Door dit formulier te verzenden gaat u akkoord met het privacyreglement van Leenattent.</div>
            </div>
          </div>
        </div>
      </div>

      <form-message v-else-if="$store.state.formSent == true" :option="'OFFERTE'"></form-message>

      <div class="sep-70"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 3em;

.area {
  background-color: getColor("greyLight");

  .layout {
    .section {
      margin: 0 pxToEm(10);

      .container {
        width: 100%;
        min-width: pxToEm(260);
        max-width: pxToEm(500);
        margin: 0 auto;

        .expandable-items {
          text-align: left;

          .anchor-error {
            position: relative;
            top: pxToEm(-20);
          }

          .exp-item {
            height: pxToEm(95);
            margin-bottom: pxToEm(30);
            background-color: getColor("bgColor");
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

            .exp-item-title-container {
              cursor: pointer;

              .exp-item-title {
                height: pxToEm(95);
                margin: 0 pxToEm(20);
                display: flex;
                align-items: center;

                &.valid {
                  color: getColor("greenDefault");
                }

                .left {
                  height: 100%;
                  flex: 2 1 auto;
                  display: flex;
                  align-items: center;
                  font-size: pxToPercent(18);
                  font-weight: 600;
                }

                .right {
                  width: pxToEm(40);
                  height: 100%;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  .arrow {
                    position: relative;
                    padding: pxToEm(5);
                    transform: rotate(45deg);
                    border: solid getColor("textColor");
                    border-width: 0 pxToEm(2) pxToEm(2) 0;
                  }
                }
              }
            }

            &.expanded {
              height: auto;

              .exp-item-title-container .exp-item-title .right .arrow {
                transform: rotate(-135deg);
              }

              .error {
                display: block;
              }
            }

            &.item3 {
              .info-container {
                .info-icon {
                  .bg {
                    width: pxToEm(40);
                    height: pxToEm(40);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: getColor("grayLtColor");
                    border-radius: 50%;
                    cursor: pointer;
                  }

                  &:hover {
                    .bg {
                      background-color: getColor("primaryLight");
                    }
                  }
                }
              }
            }

            &.item5 {
              margin-bottom: pxToEm(50);
            }

            .error {
              margin-bottom: pxToEm(50);
              color: getColor("redColor");
              font-style: italic;
              display: none;
            }

            .exp-item-text-container {
              max-width: pxToEm(300);
              margin: pxToEm(10) auto pxToEm(50);
              padding: 0 pxToEm(20);

              .exp-item-text {
                .exp-item-body {
                  display: grid;
                  grid-row-gap: @gridRowGap;

                  .txt-label {
                    margin-bottom: pxToEm(10);
                    font-size: pxToPercent(16);
                    font-weight: 300;
                  }

                  .col-11 {
                    display: grid;
                    grid-template-rows: 1fr pxToEm(52);

                    &.textarea-input {
                      grid-template-rows: 1fr pxToEm(210);
                    }

                    .info-row {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .input-dropdown {
                        width: 100%;
                        margin-right: pxToEm(20);
                      }
                    }
                  }

                  .col-111 {
                    display: grid;
                    grid-template-rows: 1fr pxToEm(68) pxToEm(52);

                    .input-slider {
                      margin-bottom: pxToEm(16);
                    }
                  }

                  .radio-input {
                    position: relative;
                  }

                  textarea {
                    text-align: left;
                    font-size: pxToPercent(16);
                    background-color: getColor("whiteColor");
                    border: @border-width solid getColor("grayLtColor");
                    resize: none;
                  }
                }

                .loans {
                  margin-bottom: pxToEm(50);

                  .sub-title {
                    margin-top: pxToEm(40);
                    margin-bottom: pxToEm(20);
                    font-size: pxToPercent(24);
                  }
                }
              }
            }
          }

          .agreement {
            margin: 0 pxToEm(2) pxToEm(30);
            line-height: 2.1em;
          }

          .btn-big {
            .content {
              margin-left: pxToEm(20);
              margin-right: pxToEm(20);

              .txt {
                margin-right: pxToEm(20);
                font-size: pxToPercent(16);
                font-weight: 700;
                text-align: left;
              }

              img {
                width: pxToEm(36);
                height: pxToEm(28);
              }
            }
          }
        }
      }
    }

    .info-anchor {
      position: relative;

      .modal-container {
        width: 100%;
        position: absolute;
        top: pxToEm(-160);
        z-index: 800;
      }
    }

    .form-message {
      margin: 0 pxToEm(25);
    }
  }
}
</style>

<script>
import inputSlider from "@/components/common/input-slider";
import inputDate from "@/components/common/input-date";
import inputNum from "@/components/common/input-num";
import inputBoolean from "@/components/common/input-boolean";
import btnBig from "@/components/common/btn-big";
import inputDropdown from "@/components/common/input-dropdown";
import inputText from "@/components/common/input-text";
import inputRadio from "@/components/common/input-radio";
import modal from "@/components/mobile/info/modal";
import formMessage from "@/components/common/form-message";
import { _person, _loanRequest, _prevLoan } from "@/data/data";
import {goToAnchor, checkTab, checkException} from "@/JS/helpers";
import { collectValidateSend, XMLtoMail, mails } from "@/JS/mail";
import Api from "@/Api";
import { DoorlopendType, PersoonlijkeType } from "@/data/loanTypes";

export default {
  name: "offerteExpItems",
  components: {
    inputSlider,
    inputNum,
    inputBoolean,
    btnBig,
    inputDropdown,
    inputText,
    inputDate,
    inputRadio,
    modal,
    formMessage
  },
  data() {
    var data = {
      person: new _person(),
      loanRequest: new _loanRequest(),
      prevLoan: new _prevLoan(),
      tabs: {},
      loanInfo: [
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Totaalprijs"],
          data: [new PersoonlijkeType(36), new PersoonlijkeType(60), new PersoonlijkeType(96), new PersoonlijkeType(120), new PersoonlijkeType(180)],
          months: ["36 maanden", "60 maanden", "96 maanden", "120 maanden", "180 maanden"]
        },
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Theoretische looptijd", "Totaalprijs"],
          data: [new DoorlopendType(56), new DoorlopendType(77), new DoorlopendType(126)],
          months: ["56 maanden", "77 maanden", "126 maanden"]
        }
      ],
      showSelectedMonths: window.document.querySelectorAll('.area .layout .black-area .black-section').length > 0
    };
    return data;
  },
  created() {
    Api.get(`percentage-section`).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        this.percentageSection = response.data.data.attributes;
      }
    });
    this.saveAmount = [];

    if ($store.state.storedData == true) {
      Object.assign(this.loanRequest, $store.state._loanRequest);
      Object.assign(this.prevLoan, $store.state._prevLoan);
      if ($store.state._loanRequest.loanOption != $store.state.funnelBOption)
        Object.assign(this.person, $store.state._person);
    };
    this.tabs = {
      tab1: {
        label: "tab1",
        origin: this.loanRequest,
        fields: ["loanOption", "approvedLoanAmount", "loanType", "months"],
        id: ".exp-item.item1",
        state: undefined
      },
      tab2: {
        label: "tab2",
        origin: this.loanRequest,
        fields: ["prevLoans"],
        id: ".exp-item.item2",
        state: undefined
      },
      tab3: {
        label: "tab3",
        origin: this.person,
        fields: [
          "married",
          "livingState",
          "children",
          "monthlyExpenses",
          "income",
          "incomeSource",
          "dateService"
        ],
        id: ".exp-item.item3",
        state: undefined
      },
      tab4: {
        label: "tab4",
        origin: this.person,
        fields: [
          "firstName",
          "middleName",
          "lastName",
          "gender",
          "birthDate",
          "streetName",
          "houseNumber",
          "affix",
          "postCode",
          "city",
          "partnerFirstName",
          "partnerMiddleName",
          "partnerLastName",
          "partnerGender",
          "partnerBirthDate",
          "partnerIncome",
          "partnerIncomeSource",
          "partnerDateService"
        ],
        id: ".exp-item.item4",
        state: undefined
      },
      tab5: {
        label: "tab5",
        origin: this.person,
        fields: [
          "emailAddress",
          "fixedPhoneNumber",
          "mobilePhoneNumber",
          "comments"
        ],
        id: ".exp-item.item5",
        state: undefined
      }
    };

    this.$root.$on('months_select_event_mobile', (loanType, monthsStr) => {
      const monthIndex = this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf(monthsStr);
      this.loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
      this.loanRequest.months = monthIndex;
    })

    this.loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
    this.loanRequest.months = this.loanRequest.months ? this.loanRequest.months : this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf($store.state.selectedMonths);
  },
  mounted() {
    this.$infoButton = this.$el.querySelector(".info-container");
    window.addEventListener("resize", this.setInfoTrianglePos);
    this.showSelectedMonths = window.document.querySelectorAll('.area .layout .black-area .black-section').length > 0;
  },
  beforeDestroy() {
    $store.state.formSent = false;
    // $store.changeFormSent(false);

    $store.state.formsOfferte = [];
    window.removeEventListener("resize", this.setInfoTrianglePos);
  },
  computed: {
    Funnel: {
      get() {
        var loanRequest = this.loanRequest;

        if (loanRequest.loanOption == $store.state.funnelBOption)
          loanRequest.selectedFunnel = $store.state.funnel.B;
        else
          loanRequest.selectedFunnel = $store.state.funnel.A;

        return loanRequest.loanOption;
      },
      set(val) {
        var loanRequest = this.loanRequest;
        loanRequest.loanOption = val;
      }
    },
    Loans: {
      get() {
        var loanRequest = this.loanRequest;
        var res = new Number(loanRequest.prevLoans.length);
        res.ui = loanRequest.prevLoans.ui;
        return res;
      },
      set(n) {
        var loanRequest = this.loanRequest;
        if (n != loanRequest.prevLoans.length) {
          if (n > 0) {
            loanRequest.prevLoans.ui.init = true;
            loanRequest.prevLoans.ui.valid = true;
          } else {
            loanRequest.prevLoans.ui.valid = false;
          }
          if (n > loanRequest.prevLoans.length) {
            for (var i = loanRequest.prevLoans.length; i < n; i++) {
              loanRequest.prevLoans.push(
                $store.state.prevLoanTemplate(false)
              );
            }
          } else loanRequest.prevLoans.splice(n);
        }
      }
    },
    getTabs() {
      if (this.loanRequest.selectedFunnel == $store.state.funnel.B)
        return 5;
      else return 4;
    }
  },
  watch: {
    "person.married": function(newVal, oldVal) {
      // *** THIS NEEDS A BETTER, CLEANER CODING SOLUTION ***
      if (this.person.married == true) {
        var tab4 = this.tabs.tab4;
        var re = /partner/;
        for (var field of tab4.fields) {
          if (re.test(field) == true && (field != "partnerMiddleName")) {
            tab4.origin[field].ui.valid = false;
          }
          if (re.test(field) == true && field == "partnerIncome") {
            if (tab4.origin[field] == 0) {
              tab4.origin[field].ui.init = false;
            } else if (tab4.origin[field] > 0) {
              tab4.origin[field].ui.init = true;
              tab4.origin[field].ui.valid = true;
            }
          }
        }
      }
    }
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize;
    },
    submitForm() {
      collectValidateSend($store, this.$route, this.tabs, this.loanRequest.prevLoans, checkTab, checkException, this.loanRequest, this.showSelectedMonths);
    },
    setInfoTrianglePos() {
      var vm = this;
      var infoRect = vm.$infoButton.getBoundingClientRect();
      var $infoModal = vm.$el.querySelector(".modal-container");
      var $infoModalTriangle = $infoModal.querySelector(".triangle");
      $infoModalTriangle.style.left =
        vm.pxToEm(0.5 * (infoRect.right + infoRect.left) - 10) + "em";
    },
    toggleModal(event) {
      var vm = this;
      event.stopPropagation();
      $store.state.modal = !$store.state.modal;
      vm.setInfoTrianglePos();
    },
    calculateSaving(amount, rate, name, index){
      let totalSaving = 0;
      this.saveAmount[index] = "";
      name = name.toString();
      if(!name || name === ""){
        name = "eerste aanbieder";
      }

      if(this.percentageSection){
        if(amount >= 50000 && (rate > this.percentageSection.Personal_loan_percentage_50000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_50000)/100) * amount;
        } else if(amount >= 25000 && (rate > this.percentageSection.Personal_loan_percentage_25000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_25000)/100) * amount;
        } else if(amount >= 15000 && (rate > this.percentageSection.Personal_loan_percentage_15000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_15000)/100) * amount;
        } else if(amount >= 10000 && (rate > this.percentageSection.Personal_loan_percentage_10000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_10000)/100) * amount;
        } else if(rate > this.percentageSection.Personal_loan_percentage_0){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_0)/100) * amount;
        }
      }

      if(totalSaving > 0){
        this.saveAmount[index] = "€ " + totalSaving.toLocaleString('nl-NL', { maximumFractionDigits: 2 }) + " bespaart u als u overstapt naar Leenattent in plaats van naar de " + name;
      }
    }
  }
};
</script>

<template>
  <div>
    <MobileCarLeasingCalculatorBlock :block="block" v-if="isMobile"></MobileCarLeasingCalculatorBlock>
    <DesktopCarLeasingCalculatorBlock :block="block" v-if="!isMobile"></DesktopCarLeasingCalculatorBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileCarLeasingCalculatorBlock from "@/components/strapi/CarLeasingCalculator/MobileCarLeasingCalculatorBlock";
import DesktopCarLeasingCalculatorBlock from "@/components/strapi/CarLeasingCalculator/DesktopCarLeasingCalculatorBlock";

export default {
  name: 'CarLeasingCalculatorBlock',
  components: {
    MobileCarLeasingCalculatorBlock,
    DesktopCarLeasingCalculatorBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>

<template>
  <div>
    <MobileInterestForFinancialLease :block="block" v-if="isMobile"></MobileInterestForFinancialLease>
    <DesktopInterestForFinancialLease :block="block" v-if="!isMobile"></DesktopInterestForFinancialLease>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileInterestForFinancialLease from "@/components/strapi/InterestForFinancialLease/MobileInterestForFinancialLease";
import DesktopInterestForFinancialLease from "@/components/strapi/InterestForFinancialLease/DesktopInterestForFinancialLease";

export default {
  name: 'InterestForFinancialLeaseBlock',
  components: {
    MobileInterestForFinancialLease,
    DesktopInterestForFinancialLease
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      console.log("I am called");
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>